import { computed } from 'vue'
import { useScreen, useRouteName } from '@/composables'

export default function useSelectedPlanCard () {
  const { isSmall } = useScreen()
  const { isConclusionPage, isConfirmPage } = useRouteName()

  const useSmallSelectedPlan = computed(() => {
    return isSmall.value && (!isConfirmPage.value && !isConclusionPage.value)
  })
  
  const selectedPlanPositionStyles = computed(() => {
    if (useSmallSelectedPlan.value) return 'position: relative; transform: translateY(-50%); margin-bottom: -15px'
    else return `
      position: relative; 
      transform: translateY(${showInformationalCard.value ? '-22%' : '-40%'}); 
      margin-bottom: ${useSmallSelectedPlan.value ? '-15px' : '-120px'}
    `
  })

  const showInformationalCard = computed(() => {
    return !useSmallSelectedPlan.value
  })  

  return {  
    useSmallSelectedPlan, 
    selectedPlanPositionStyles,
    showInformationalCard
  }
}