<template>
  <div class="we-select relative" v-click-outside="hideMenu">
    <we-input :name="name" v-model="selectedOption" :icon="icon" v-bind="$attrs" @click.native="handleArrowClick" class="we-select__input cursor-pointer"/>

    <div v-if="isMenuOpen" class="we-select__menu absolute we-10 rounded-md bg-white text-purple-default w-full mt-2 shadow-lg shadow-purple-light-opacity overflow-y-scroll z-20">
      <div v-for="(option, index) in options" :key="index" @click="setOptionSelected(option)">
       <div class="border-solid border-1 p-4 border-b border-purple-light-opacity font-bold cursor-pointer hover:bg-purple-light-opacity">
        {{ option.label }}
       </div>
      </div>
    </div>
    
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { directive as vClickOutside } from "click-outside-vue3"
import WeInput from '@/components/WeInput'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  name: {
    type: String,
    required: true
  },  

  modelValue: {
    type: [String, Number, Object],
    default: ''
  },

  options: {
    type: Array,
    default: () => []
  },

  icon: {
    type: String,
    default: 'arrow_drop_down'
  },
})

//menu
const isMenuOpen = ref(false)

const openMenu = () => isMenuOpen.value = true

const hideMenu = () => isMenuOpen.value = false

const handleArrowClick = () => isMenuOpen.value ? hideMenu() : openMenu()

const selectedOption = computed(() => { 
  if(props.name === 'neighborhood'){ 
    return props.options.find(option => option.value === props.modelValue.value)?.label || '';
  } else { 
    return props.options.find(option => option.value === props.modelValue)?.label || '' ;
  }
})
  
const setOptionSelected = (option) => {

    if(props.name === 'neighborhood'){
      emit('update:modelValue', option)
    } else {
      emit('update:modelValue', option.value)
    }
  
    hideMenu()
}

</script>

<style lang="scss">
.we-select {
  &__menu {
    max-height: 200px;
  }
}
</style>