<template>
  <div class="skeleton desktop-layout relative flex h-screen flex-col" v-show="isLoading">
    <div class="w-full px-24 py-10 pb-0 flex justify-between items-center"> 
      <div class="h-20 w-40 rounded-xl bg-gray-200 animate-pulse"></div>
      <div class="h-10 w-60 rounded-full bg-gray-200 animate-pulse"></div>
    </div>
    <div class="flex w-full h-full">

      <div class="w-1/2 bg-white px-24 flex flex-col justify-center items-center ">
          <div class="w-full flex justify-between mb-8"> 
            <div class="h-8 w-8 rounded-full bg-gray-200 animate-pulse"></div>
            <div class="h-8 w-8 rounded-full bg-gray-200 animate-pulse"></div>
            <div class="h-8 w-8 rounded-full bg-gray-200 animate-pulse"></div>
            <div class="h-8 w-8 rounded-full bg-gray-200 animate-pulse"></div>
          </div>
          <div class="w-full flex flex-col mb-10"> 
            <div class="h-8 w-1/2 rounded-full bg-gray-200 animate-pulse mb-4"></div>
            <div class="h-4 w-full rounded-full bg-gray-200 animate-pulse mb-2"></div>
            <div class="h-4 w-3/4 rounded-full bg-gray-200 animate-pulse mb-2"></div>
            <div class="h-4 w-3/4 rounded-full bg-gray-200 animate-pulse"></div>
          </div>
          <div class="w-full flex flex-col mb-10">
            <div class="bg-gray-200 animate-pulse rounded-full w-full h-10 px-6 py-3 mb-3"></div>
            <div class="bg-gray-200 animate-pulse rounded-full w-full h-10 px-6 py-3 mb-3"></div>
            <div class="bg-gray-200 animate-pulse rounded-full w-full h-10 px-6 py-3 "></div>
          </div>
          <div class="w-full flex flex-col mb-10">
            <div class="h-4 w-2/5 rounded-full bg-gray-200 animate-pulse mb-2"></div>
            <div class="bg-gray-200 animate-pulse rounded-full w-full h-10 px-6 py-3 mb-3"></div>
            <div class="bg-gray-200 animate-pulse rounded-full w-full h-10 px-6 py-3"></div>
          </div>
          <div class="w-full flex">
            <div class="bg-gray-200 animate-pulse rounded-full w-2/3 h-10 px-6 py-3"></div>
          </div>        
      </div>

      <div class="w-1/2 bg-white px-24 flex flex-col justify-center items-center">
          <div class="w-full flex mb-8"> 
            <div class="p-10 w-full rounded-xl border border-gray-200 animate-pulse">
              <div class="h-8 w-1/2 rounded-full bg-gray-200 animate-pulse mb-4"></div>
              <div class="h-4 w-4/12 rounded-full bg-gray-200 animate-pulse mb-8"></div>
              <div class="h-4 w-5/12 rounded-full bg-gray-200 animate-pulse"></div>
            </div>
            
          </div>
          <div class="w-full flex mb-8"> 
            <div class="p-10 w-full rounded-xl border border-gray-200 animate-pulse">
              <div class="h-8 w-full rounded-full bg-gray-200 animate-pulse mb-6"></div>
              <div class="h-4 w-full rounded-full bg-gray-200 animate-pulse mb-3"></div>
              <div class="h-4 w-full rounded-full bg-gray-200 animate-pulse mb-3"></div>
              <div class="h-4 w-full rounded-full bg-gray-200 animate-pulse mb-3"></div>
              <div class="h-4 w-full rounded-full bg-gray-200 animate-pulse mb-3"></div>
              <div class="h-4 w-full rounded-full bg-gray-200 animate-pulse mb-3"></div>
              <div class="h-4 w-full rounded-full bg-gray-200 animate-pulse mb-3"></div>
              <div class="h-4 w-full rounded-full bg-gray-200 animate-pulse mb-3"></div>
              <div class="h-4 w-3/4 rounded-full bg-gray-200 animate-pulse"></div>
            </div>
            
          </div>
      </div>
    </div>  
  </div>

  <div class="desktop-layout relative flex h-screen" v-show="!isLoading">
    <we-header class="fixed z-40" :target="target"/>
    
    <!-- content div (left side) -->
    <div class="desktop-layout__left-side w-1/2 bg-white flex px-24 overflow-y-scroll" :class="contentClass">
      <slot name="content-desktop" />
    </div>

    <div class="desktop-layout__right-side w-1/2 overflow-y-scroll px-24 py-40" :class="target">
      <slot name="desktop-plan-information" />
    </div>
  </div>

  
  
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import WeHeader from '../components/WeHeader.vue'

const route = useRoute()
const store = useStore()

//content
const contentClass = computed(() => {
  return route.name === 'Confirm' ? 'items-center' : 'py-40'
})

const target = computed(() => {
  return store.getters.getTarget ? store.getters.getTarget : ''
})

const isLoading = computed(() => {
  return store.getters.getLoading;
})

</script>

<style lang="scss">
.desktop-layout {
  
    &__logo {
      width: 130px;
      height: 60px;
    }

    &__left-side {
      &::-webkit-scrollbar {
        display: none;
      }
    }
    
    &__right-side {

      @apply bg-secondary-brand-300;

      &::-webkit-scrollbar {
        display: none;
      }  

      &.business{
        .we-selected-plan-card{
          div{
            @apply shadow-none
          }
        }
      }

    }

    .business{
        @apply bg-white;
    }

    &.skeleton{
      > div{
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

}
</style>