export default {
  state: {
    stepper: {
      1: {
        state: 1,
        name: 'Availability',
        completed: false
      },
      2: {
        state: 2,
        name: 'ContractData',
        completed: false
      },
      3: {
        state: 3,
        name: 'Portability',
        completed: false
      },
      4: {
        state: 4,
        name: 'Payment',
        completed: false
      },
      5: {
        state: 5,
        name: 'Installation',
        completed: false
      },
      99: {
        state: 99,
        name: 'Revision',
        completed: false
      }
    },
    activeStep: 0
  },

  getters: {
    getActiveStep: state => state.activeStep,

    getStepper: state => (stepState = null) => {
      if (!stepState) return state.stepper

      else return state.stepper[stepState]
    },

    getStepperByRouteName: state => routeName => {
      const step = Object.values(state.stepper).find(step => step.name === routeName)
      return step
    }
  },

  actions: {
    updateStep ({ commit }, { stepState, value }) {
      commit('updateStep', { stepState, value })
    }, 

    markCompleted ({ commit }, stepState) {
      commit('markCompleted', stepState)
    },

    setActiveStep ({ commit }, stepState) {
      commit('setActiveStep', stepState)
    },

    navigateBack ({ commit }, { stepState, value = false }) {
      commit('updateStep', { stepState, value: true })
      commit('setActiveStep', stepState)
    },

    resetSteps ({ commit }) {
      commit('setActiveStep', 0)
      commit('resetSteps')
    }
  },

  mutations: {
    updateStep (state, { stepState, value }) {
      if (!stepState) return
      state.stepper[stepState].completed = value
    },

    markCompleted (state, stepState) {
      state.stepper[stepState].completed = true
    },

    setActiveStep (state, stepState) {
      state.activeStep = stepState
    },

    resetSteps (state) {
      Object.keys(state.stepper).forEach(key => state.stepper[key].completed = false)
    }
  }
}