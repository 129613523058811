<template>
  <div class="we-plan-card shadow-md shadow-contrast-brand-400/10 py-6 bg-white rounded-3xl w-[256px] relative border border-gray-100 mt-4 pt-16">
    <div class="w-20 h-20 bg-contrast-brand-300 rounded-full absolute top-0 -translate-y-1/2 right-0 left-0 mx-auto flex items-center justify-center flex-col outline outline-8 outline-contrast-brand-200/30">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 31 24" fill="none">
        <path data-v-1777c14c="" d="M13.1464 20.9673C13.1464 20.5063 13.283 20.0557 13.5389 19.6724C13.7948 19.2891 14.1586 18.9904 14.5841 18.8139C15.0097 18.6375 15.478 18.5914 15.9298 18.6813C16.3815 18.7712 16.7965 18.9932 17.1222 19.3192C17.448 19.6452 17.6698 20.0604 17.7596 20.5126C17.8495 20.9647 17.8034 21.4333 17.6271 21.8592C17.4508 22.2851 17.1523 22.6491 16.7693 22.9052C16.3863 23.1613 15.936 23.298 15.4754 23.298C14.8577 23.298 14.2653 23.0525 13.8286 22.6154C13.3918 22.1783 13.1464 21.5854 13.1464 20.9673ZM23.7084 16.0214C24.145 15.5844 24.3903 14.9916 24.3903 14.3736C24.3903 13.7556 24.145 13.1628 23.7084 12.7258C21.5246 10.541 18.5632 9.3137 15.4754 9.3137C12.3876 9.3137 9.4262 10.541 7.24246 12.7258C6.81822 13.1653 6.58347 13.7541 6.58878 14.3652C6.59408 14.9763 6.83902 15.5609 7.27083 15.993C7.70264 16.4252 8.28678 16.6703 8.89743 16.6756C9.50808 16.6809 10.0964 16.446 10.5356 16.0214C11.8671 14.7513 13.636 14.0427 15.4754 14.0427C17.3149 14.0427 19.0837 14.7513 20.4152 16.0214C20.8519 16.4584 21.4442 16.7038 22.0618 16.7038C22.6793 16.7038 23.2716 16.4584 23.7084 16.0214ZM30.297 9.43008C30.7337 8.993 30.9789 8.40027 30.9789 7.78224C30.9789 7.16421 30.7337 6.57148 30.297 6.1344C26.3631 2.20614 21.0327 0 15.4754 0C9.91809 0 4.58772 2.20614 0.653772 6.1344C0.229529 6.57398 -0.00521835 7.16274 8.80417e-05 7.77385C0.00539444 8.38497 0.25033 8.96955 0.682142 9.40169C1.11395 9.83383 1.69809 10.0789 2.30874 10.0843C2.91939 10.0896 3.5077 9.85464 3.94695 9.43008C7.0077 6.37628 11.1534 4.66146 15.4754 4.66146C19.7974 4.66146 23.9431 6.37628 27.0039 9.43008C27.4406 9.86702 28.0329 10.1125 28.6505 10.1125C29.268 10.1125 29.8603 9.86702 30.297 9.43008Z" fill="white"></path>
      </svg>
      <div class="text-white font-black text-2xl">{{ speed }}</div>
      <div class="text-white font-medium text-sm uppercase -mt-1">mega</div>
    </div>
    
    <div class="text-primary-brand-300 font-bold text-lg text-center mb-2 whitespace-normal px-2">{{ plan.title }}</div>

    <div class="mb-6">

      <!-- <div v-if="type == 'plan'" class="flex flex-col items-center relative">

        <div class="w-10 aspect-square flex items-center justify-center rounded-full border border-contrast-lightness">
            <img :src="plan.icon" class="w-full h-full object-contain p-2">
        </div>

      </div> -->

      <div v-if="type != 'plan'" class="flex items-center justify-center gap-x-1.5">

        <div v-for="(feature, index) in plan.features" :key="index">
            <div v-if="feature.enable" class="w-10 aspect-square flex items-center justify-center rounded-full border border-secondary-brand-300">
              <img :src="feature.icon" class="w-full h-full object-contain p-2">
            </div>
        </div>

      </div>

    </div>
    
    <div class="flex flex-wrap mx-auto max-w-[142px]" :class="priceBoxSize">
      <div class="flex">
        <div class="flex flex-wrap items-end">
          <div class="font-medium text-2xl text-primary-brand-400 mb-1 mr-0.5 italic">R$</div>
          <div class="text-primary-brand-400 font-extrabold text-5xl">{{ plan.price }}</div>
        </div>
        <div class="flex flex-col">
          <div class="font-medium text-2xl text-primary-brand-400 leading-none">,{{ plan.price_decimal }}</div>
          <div class="text-sm font-semibold text-[#A29E9E] text-left">por mês</div>
        </div>
      </div>
    </div>
        
    <div class="px-8 mt-4">
      <we-button class="w-full bg-contrast-brand-300 rounded-full text-white font-extrabold text-lg" label="Quero esse plano" medium no-border no-icon :action="() => updateSelectedPlan(plan)"/>
    </div>

    <div class="flex px-6 mt-2 min-h-[]">

      <div v-if="type == 'plan'" class="flex whitespace-normal text-center leading-1 text-xs text-gray-400">
        {{ plan.observation }}
      </div>
      <div v-else class="flex whitespace-normal text-center leading-1 text-xs text-gray-400">
        {{ plan.note }}
      </div>
    </div>

  </div>
</template>

<script setup>

  import { computed } from 'vue'
  import WeButton from './WeButton.vue'

  const emit = defineEmits('selectedPlan')

  const props = defineProps({
    plan: {
      type: Object,
      default: () => {}
    }
  })

  const type = computed(() => props.plan.type);

  const updateSelectedPlan = (plan) => {
      emit('selectedPlan', plan);
  };

  const priceBoxSize = computed(() => {
    const priceString = props.plan.price.value + ''
    return priceString.length > 2 ? 'max-w-[172px]' : 'max-w-[142px]'
  })

  const speed =  computed(() => {
      if (props.plan?.type != 'combo') {
          return props.plan?.describe?.substring(0,3);
      } else {
          return props.plan?.speed?.substring(0,3);
      }
  })

</script>

