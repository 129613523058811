<template>
  <Teleport to="body">
    <!-- <div v-if="modelValue" class="we-modal z-50 flex md:px-10" :class="modalClasses" @click.self="hideModal" v-click-outside="hideModal"> -->
    <div v-if="modelValue" class="we-modal z-50 flex md:px-10" :class="modalClasses">
      <div class="we-modal__wrapper h-auto rounded-3xl pt-14 pb-8 md:pt-10 bg-white shadow-sm relative" :class="modalSize">
        <div>
          <div v-if="!noCloseButton" class="absolute right-5 top-5 cursor-pointer">
            <we-icon name="close" class="fill-primary-brand-200" @click="hideModal"/>
          </div>
          <slot name="header"/>
        </div>
        <div>
          <slot name="content"/>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { computed } from 'vue'
import { directive as vClickOutside } from "click-outside-vue3"
import { useScreen } from '@/composables'
import WeIcon from './WeIcon'

const emit = defineEmits(['update:modelValue', 'close'])

const props = defineProps({
  modelValue: {
    type: Boolean
  },

  modalSize: {
    type: String,
    default: 'max-w-[100vw] md:w-[90vw]'
  },

  noCloseButton: {
    type: Boolean
  }
})

const { isSmall } = useScreen()

const hideModal = () => {
  emit('update:modelValue', false)
  emit('close')
}

const modalClasses = computed(() => {
  return isSmall.value ? 'bottom-0 fixed h-[100vh] w-full overflow-hidden justify-center items-center bg-black bg-opacity-50' : 'justify-center items-center absolute w-screen h-screen top-0 left-0'
})
</script>

<style lang="scss" scoped>
.we-modal {
  @media (min-width: 769px) {
    background-color: rgba(0,0,0, .3);
  }
}
</style>