import { createStore } from 'vuex'
import stepper from './stepper'
import form from './form'
import selectedPlan from './selected-plan'
import load from './load'

export default createStore({
  modules: {
    stepper,
    form,
    selectedPlan,
    load
  }
})
