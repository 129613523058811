<template>

  <div v-if="!useBlurredButton" class="we-button flex cursor-pointer" @click="action" :class="[buttonDisabled, { 'pointer-events-none': loading }]">
    <div v-bind="$attrs" class="flex items-center justify-center relative" :class="[buttonSpacing, buttonBorders]">
      
      <div v-if="loading && !changePlan" class="pointer-events-none">
        <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>

      <div v-if="loading && changePlan" class="pointer-events-none">
        <svg class="animate-spin h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>

      <we-icon v-if="!noIcon && !loading" class="p-0 mr-1" name="edit" :class="weIconClasses" :size="iconOptions.size"/>

      <span v-if="!loading" :class="labelClasses" class="font-extrabold text-sm">{{ label }}</span>

    </div>
  </div>

  <Teleport v-else to="body">
    <div class="fixed bottom-0 w-full">
      <we-blurred-box class="w-full">
        <div class="we-button flex cursor-pointer w-full" @click="action" :class="buttonDisabled">
          <div v-bind="$attrs" class="flex items-center justify-center w-full" :class="[buttonSpacing, buttonBorders]">
            <div v-if="loading" class="pointer-events-none">
              <svg width="20" height="20" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#FFF">
                <g fill="none" fill-rule="evenodd">
                  <g transform="translate(1 1)" stroke-width="2">
                      <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                      <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
                      </path>
                  </g>
                </g>
              </svg>
            </div>

            <we-icon v-if="!noIcon && !loading" class="p-0 mr-1" name="edit" :class="weIconClasses" :size="iconOptions.size"/>
            <span v-if="!loading" :class="labelClasses" class="font-extrabold text-sm">{{ label }}</span>
          </div>
        </div>
      </we-blurred-box>
    </div>
  </Teleport>

</template>

<script setup>
import { computed } from 'vue'
import { useScreen } from '@/composables'
import WeIcon from './WeIcon.vue'
import WeBlurredBox from './WeBlurredBox'

const props = defineProps({
  label: {
    type: String,
    default: 'Label'
  },

  action: {
    type: Function,
    default: () => {}
  },

  iconColor: {
    type: String,
    default: ''
  },

  labelClasses: {
    type: [String, Object],
    default: ''
  },

  labelColor: {
    type: String,
    default: ''
  },

  borderColor: {
    type: String,
    default: 'primary-color-200'
  },

  small: {
    type: Boolean
  },

  medium: {
    type: Boolean
  },

  noBorder: {
    type: Boolean
  },

  noIcon: {
    type: Boolean
  },

  iconOptions: {
    type: Object,
    default: () => ({})
  },

  disabled: {
    type: Boolean
  },

  useBlurredButton: {
    type: Boolean
  },

  loading: {
    type: Boolean,
    default: false
  },

  changePlan: {
    type: Boolean,
    default: false
  },

  card: {
    type: Boolean,
    default: false
  }

})

const { isSmall } = useScreen()

//button
const useBlurredButton = computed(() => {
  return isSmall.value && props.useBlurredButton
})

const buttonDisabled = computed(() => {
  return props.disabled ? 'opacity-50 pointer-events-none' : ''
})


const buttonSpacing = computed(() => {
  return `${props.small ? 'px-3 py-1' : props.medium ? 'px-3 py-2' : 'px-3 py-4'}`
})

const buttonBorders = computed(() => {
  if (props.noBorder) return ''

  return `border-2 rounded-full border-${props.borderColor}`
})

// icon
const weIconClasses = computed(() => {
  return [`fill-${props.iconColor}`, props.iconOptions.classes]
})
</script>
