import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'Confirm',
  //   component: () => import('@/views/Confirm')
  // },
  {
    path: '/',
    name: 'Availability',
    component: () => import('@/views/Availability')
  },
  {
    path: '/session/:id?',
    name: 'Session',
    component: () => import('@/views/Availability')
  },
  {
    path: '/dados-do-contrato',
    name: 'ContractData',
    component: () => import('@/views/ContractData')
  },
  {
    path: '/portabilidade',
    name: 'Portability',
    component: () => import('@/views/Portability')
  },
  {
    path: '/pagamento',
    name: 'Payment',
    component: () => import('@/views/Payment')
  },
  {
    path: '/instalacao',
    name: 'Installation',
    component: () => import('@/views/Installation')
  },
  {
    path: '/revisao',
    name: 'Revision',
    component: () => import('@/views/Revision')
  },
  {
    path: '/conclusao',
    name: 'Conclusion',
    component: () => import('@/views/Conclusion')
  },
  {
    path: '/suporte',
    name: 'ScheduleYourService',
    component: () => import('@/views/ScheduleYourService')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
