import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default function useRouteName () {
  const route = useRoute()

  const isConfirmPage = computed(() => {
    return route.name === 'Confirm'
  })
  
  const isConclusionPage = computed(() => {
    return route.name === 'Conclusion'
  })
  
  return { isConfirmPage, isConclusionPage }
}





