<template>
  <component v-if="useDynamicComponents" :is="layout">
    <template #desktop-plan-information>
      <div class="relative">
        <div v-if="!isSmall" class="w-full md:max-w-lg">
          <we-selected-plan-card />
        </div>
      </div>
    </template>

    <template #content-mobile>
      <div class="content-mobile absolute pb-32 w-full px-6" :style="contentPositionStyle">
        <div v-if="isSmall" class="w-full -translate-y-1/2 -mb-8 md:max-w-lg">
          <we-selected-plan-card />
        </div>        
        <router-view :key="$route.path"/>
      </div>
    </template>


    <template #content-desktop>
      <div class="relative w-full">
        <div v-if="isSmall" class="w-full md:max-w-lg mb-8">
          <we-selected-plan-card />
        </div>        
        <div class="content-desktop-router-view">
          <router-view :key="$route.path" />
        </div>        
      </div>
    </template>
  </component>

  <router-view :key="$route.path" v-else/>
</template>

<script setup>
import '@/assets/tailwind.css'
import { computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useScreen, useSelectedPlanCard, useWeButton } from './composables'
import DesktopLayout from './layouts/DesktopLayout'
import MobileLayout from './layouts/MobileLayout'
import WeSelectedPlanCard from './components/WeSelectedPlanCard'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const store = useStore()
const route = useRoute()
const router = useRouter()

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load()

const activeStep = computed(() => store.getters.getActiveStep)

//Screen setup
const { useSmallSelectedPlan } = useSelectedPlanCard()
const { setResizeListener, isSmall } = useScreen()
setResizeListener()

//Layout dynamic structure
const useDynamicComponents = computed(() => {
  const excludedRoutes = ['Conclusion', 'ScheduleYourService']

  if (excludedRoutes.includes(route.name)) return false

  return true
})

const layout = computed(() => {
  return isSmall.value ? MobileLayout : DesktopLayout
})

const contentPositionStyle = computed(() => {
  return useSmallSelectedPlan.value ? 'transform: translateY(-42px)' : 'margin-top: 24px'
})

watch(route, newRoute => {

  // resets all data if user is back to Confirm again
  if (newRoute.name === 'Confirm') resetStates()
  
  if (store.getters.getActiveStep == 'undefined') store.dispatch('setActiveStep', 4) 

  //get activeStep when user navigate using arrows
  const toStep = store.getters.getStepperByRouteName(newRoute?.name)

  store.dispatch('setActiveStep', toStep?.state)

})

//Stepper flow
const resetStates = () => {
  store.dispatch('resetSteps')

  store.dispatch('resetForm')
}

onMounted(async () => {    

  store.dispatch('setCepError', false);

  await router.isReady()

  if( route.params.id ) {

    try {
      const session = await store.dispatch('fetchSelectedPlanBySession', route.params.id)

      // store.dispatch('fetchPlansList', route.params.id)

      store.dispatch('inputFormObjectValues', {    
          name: session.prospect.name,
          email: session.prospect.email,
          phone: session.prospect.cellphone || session.prospect.phone,
          address_id: session.prospect.addresses?.address_id,
          address_name: session.prospect.addresses?.address_name,
          address_number: session.prospect.addresses?.number,
          address_complement: session.prospect.addresses?.complement,
          cpf: session.prospect.cpf,
          rg: session.prospect.rg,
          mother_name: session.prospect.mother_name,
          birth_date: session.prospect.birth_date,
          cnpj: session.prospect.cnpj,
          company_name: session.prospect.company_name,
          state_registration: session.prospect.state_registration,
          municipal_registration: session.prospect.municipal_registration,
          fantasy_name: session.prospect.fantasy_name,
          terms: false,
          payment_day_of_month: session.prospect.payment_day_of_month,
          code: session.promo_code.code,
          day: session.installation.date,
          period: session.installation.period,
          home_type: session.installation.home_type,
          observation: session.installation.observation,
          serviceContactType: ''
      })

      store.dispatch('setActiveStep', session.current_step)

      if (session.current_step) {
        
        for (let i = 1; i < session.current_step; i++) {

          store.dispatch('updateStep', { stepState: i, value: true })
        }

        let stepRouteName = ''

        stepRouteName = store.getters.getStepper(session.current_step).name          
        
        return router.push({ name: stepRouteName, replace: true })

      } else {
        
        return router.push({ name: 'Availability', replace: true })
        
      }
    } catch (e) {
      localStorage.clear()
      console.log('houve um erro->', e)
    }

  } else {    

    const selectedCity = route.query.selected_city_id
    const selectedPlan = route.query.selected_plan_id
    const selectedPlanType = route.query.type
    const target = route.query.target
    const promoCode = route.query.promo ?? '';
    const affiliate = route.query.a ?? '';

    if((!selectedCity || !selectedPlan || !selectedPlanType) && localStorage.getItem('session_uuid')){

        //fetch initial data
        try {
          const session = await store.dispatch('fetchSelectedPlanBySession', localStorage.getItem('session_uuid'))

          const objs = Object.keys(store.getters.getStepper()).length

          // store.dispatch('fetchPlansList', localStorage.getItem('session_uuid'))

          store.dispatch('inputFormObjectValues', {
              name: session.prospect?.name,
              email: session.prospect?.email,
              phone: session.prospect?.cellphone || session.prospect?.phone,
              address_id: session.prospect?.addresses?.address_id,
              address_name: session.prospect?.addresses?.address_name,
              address_number: session.prospect?.addresses?.number,
              address_complement: session.prospect?.addresses?.complement,
              cpf: session.prospect?.cpf,
              rg: session.prospect?.rg,
              mother_name: session.prospect?.mother_name,
              birth_date: session.prospect?.birth_date,
              cnpj: session.prospect?.cnpj,
              company_name: session.prospect?.company_name,
              state_registration: session.prospect?.state_registration,
              municipal_registration: session.prospect?.municipal_registration,
              fantasy_name: session.prospect?.fantasy_name,
              terms: false,
              payment_day_of_month: session.prospect?.payment_day_of_month,
              code: session.promo_code?.code,
              day: session.prospect?.day,
              period: session.prospect?.period,
              home_type: session.prospect?.home_type,
              observation: session.prospect?.observation,
              serviceContactType: ''
          })

          store.dispatch('setActiveStep', session.current_step)

          if (session.current_step) { 

            if(session.current_step == 99){
                localStorage.clear();
                window.location = process.env.VUE_APP_WECLIX_SITE ?? 'https://www.weclix.com.br'
            }

            for (let i = 1; i < session.current_step; i++) { 
                store.dispatch('updateStep', { stepState: i, value: true })
            }

            let stepRouteName = ''

            stepRouteName = store.getters.getStepper(session.current_step).name
            
            return router.push({ name: stepRouteName, replace: true })            

          } else {
            
            return router.push({ name: 'Availability', replace: true })

          }

        } catch (e) {    
          localStorage.clear()       
          console.log('houve um erro->', e)             
        }
      
    } else {     

      let visitorId = ''

      // Get the visitor identifier when you need it.
      await fpPromise
        .then(fp => fp.get())
        .then(result => visitorId = result.visitorId)
      
      await store.dispatch('fetchSelectedPlan', { selectedCity, selectedPlan, selectedPlanType, visitorId, target, promoCode, affiliate })

    }

    //should always start on Availability page, doesn't matter where the user came from since it is the first load
    
    router.push({ name: 'Availability', replace: true })

  }
  

})


</script>