<template>
  <div class="we-input">
    <div v-if="isTextArea">
      <textarea v-if="!isTextAreaReadOnly" :value="modelValue" class="we-input__textarea-box rounded-2xl border border-primary-brand-100 p-6 pr-2 outline-none w-full group h-[265px]" :class="[errorClasses, textAreaClasses]" v-bind="$attrs" @input="$event => updateModelValue($event.target.value)"/>
      <div v-else class="we-input__textarea-box rounded-2xl border border-primary-brand-100 p-6 pr-2 outline-none w-full group h-[265px] overflow-y-scroll" v-bind="$attrs" v-html="modelValue" />
    </div>
    <div v-else class="relative">
      <slot name="input">
        <input v-if="!useMask" :type="type" v-bind="$attrs" :value="modelValue" v-mask="inputMask" class="peer border border-primary-brand-100 rounded-full w-full px-6 py-3 font-semibold text-primary-brand-200 text-sm outline-none" autocomplete="false" @input="$event => updateModelValue($event.target.value)" @blur="$event => checkRules($event.target.value)" :class="errorClasses">
        <input v-else :type="type" v-bind="$attrs" :value="modelValue" class="peer border border-primary-brand-100 rounded-full w-full px-6 py-3 font-semibold text-primary-brand-200 text-sm outline-none" autocomplete="false" @input="$event => updateModelValue($event.target.value)" @blur="$event => checkRules($event.target.value)" :class="errorClasses">
      </slot>
      <slot name="icon">
        <div v-if="loading" class="pointer-events-none absolute we-input__icon-wrapper top-1/2 -translate-y-1/2 right-4">
          <svg width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#1FC7BD">
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="2">
                    <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
                    </path>
                </g>
            </g>
          </svg>
        </div>
        

        <we-icon v-if="hasIcon" :name="iconName" :size="iconSize" class="pointer-events-none absolute we-input__icon-wrapper top-1/2 -translate-y-1/2 right-4" :classes="iconClasses"/>
      </slot>
    </div>
  </div>
  <div class="w-full text-right">
    <span v-if="hasError || showError" class="text-xs font-semibold text-red-500 mr-4">{{ errorMessage }}</span>
  </div>
</template>

<script setup>
import { computed, ref, useAttrs } from 'vue'
import WeIcon from './WeIcon';

const attrs = useAttrs()

const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean],
    default: ''
  },

  type: {
    type: String,
    default: 'text'
  },

  iconSize: {
    type: [String, Object],
    default: '24px'
  },

  icon: {
    type: String,
    default: ''
  },

  mask: {
    type: String,
    default: ''
  },

  noMask: {
    type: Boolean,
    default: true
  },

  rules: {
    type: [String, Function],
    default: ''
  },
  
  errorMessage: {
    type: String,
    default: ''
  },

  showError: {
    type: Boolean,
    default: false
  },

  loading: {
    type: Boolean,
    default: false
  }
})

//events
const emit = defineEmits(['update:modelValue'])

const updateModelValue = value => emit('update:modelValue', value)

//inputs
const isReadOnly = computed(() => attrs.readonly)

const isTextAreaReadOnly = computed(() => {
  return isReadOnly.value && isTextArea.value
})

const isTextArea = computed(() => props.type === 'textarea')

const textAreaClasses = computed(() => {
  if (isReadOnly.value) return

  return {
    'hover:border-2': !hasError.value,
    'hover:border-2': !hasError.value,
    'hover:border-contrast-brand-300': !hasError.value
  }
})

const hasError = ref(false)

const checkRules = value => {
  if (!props.rules) return
  hasError.value = !props.rules(value.replace(/[^a-z0-9]/g, ''))
}

const errorClasses = computed(() => {
  return {
    'border-red-500': hasError.value || props.showError,
    'focus:border-contrast-brand-300': !hasError.value && !props.showError
  }
})

const hasIcon = computed(() => !!props.icon && !props.loading)

const iconName = computed(() => {
  return hasError.value ? '' : props.icon
})

const iconClasses = computed(() => {
  return {
    'fill-primary-brand-300': !hasError.value,
    'peer-focus:fill-contrast-brand-300': !hasError.value,
    'fill-red-500': hasError.value
  }
})

const useMask = computed(() => {
  return !props.mask || !props.noMask
})

const inputMask = computed(() => {
  if (!props.mask) return 'A'

  return {
    'phone': ['(##) ####-####', '(##) #####-####'],
    'personal-document': '###.###.###-##',
    'general-register': '##.###.###-X',
    'date': '##/##/####',
    'postal-code': '#####-###',
    'date': '##/##/####',
    'cnpj': '##.###.###/####-##'
  }[props?.mask] || ''
})
</script>

<style lang="scss" scoped>
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
.we-input {
  &__textarea-box {
    resize: none;
  }

  &__textarea-box::-webkit-scrollbar {
    width: 4px;
    border-radius: 30px;
  }

  &__textarea-box::-webkit-scrollbar-track {
    border-radius: 30px;
    background-color: #1FC7BD33;
    padding: 100px 0;
  }

  &__textarea-box::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #1CB4AB;
  }
}
</style>