import axios from "axios"

export default {
  state: {
    form: {
      // Nome completo
      name: '',
      // email
      email: '',
      // telefone
      phone: '',
      // ID DO ENDEREÇO
      address_id: '',
      // Endereço
      address_name: '',
      // numero da rua
      address_number: '',
      // complemento
      address_complement: '',      
      // cpf
      cpf: '',
      // rg
      rg: '',
      // nome da mae
      mother_name: '',
      // data de nascimento
      birth_date: '',
      // cnpj
      cnpj: '',
      // razão social
      company_name: '',
      // inscrição estadual
      state_registration: '',
      // inscrição municipal
      municipal_registration: '',
      // nome fantasia
      fantasy_name: '',
      // aceitar termos
      terms: false,
      // data de vencimento do boleto
      payment_day_of_month: '',
      // cupom
      promo_code: '',
      // dia da instalaçao
      day: '',
      // periodo da instal
      period: '',
      // tipo de res.
      home_type: '',
      // obsvervação do cliente
      observation: '',
      // tipo de atendimento
      serviceContactType: ''
    },
    errors: {
      // Nome completo
      name: false,
      // email
      email: false,
      // telefone
      phone: false,
      // ID DO ENDEREÇO
      address_id: false,
      // Endereço
      address_name: false,
      // numero da rua
      address_number: false,
      // complemento
      address_complement: false,      
      // cpf
      cpf: false,
      // rg
      rg: false,
      // nome da mae
      mother_name: false,
      // data de nascimento
      birth_date: false,
      // cnpj
      cnpj: false,
      // razão social
      company_name: false,
      // inscrição estadual
      state_registration: false,
      // inscrição municipal
      municipal_registration: false,
      // nome fantasia
      fantasy_name: false,
      // aceitar termos
      terms: false,
      // data de vencimento do boleto
      payment_day_of_month: false,
      // cupom
      promo_code: false,
      // dia da instalaçao
      day: false,
      // periodo da instal
      period: false,
      // tipo de res.
      home_type: false,
      // obsvervação do cliente
      observation: false,
      // tipo de atendimento
      serviceContactType: false
    }
  },

  getters: {
    getForm: state => state.form,

    getErrors: state => state.errors,

    periodsList: _ => {
      return [
        {
          label: 'De manhã, entre as 8h e as 12h',
          value: 'morning'
        },
        {
          label: 'A tarde, entre as 13h e as 18h',
          value: 'afternoon'
        }
      ]
    },

    residenceTypesList: _ => [
      {
        label: 'Casa',
        value: 'house'
      },
      {
        label: 'Condomínio - Casa',
        value: 'condominiumHouse'
      },
      {
        label: 'Condomínio - Apartamento',
        value: 'buildingApartment'
      }
    ]
  },

  actions: {
    setSingleFormValue: ({ commit }, { key, value }) => {
      commit('setFormValue', { key, value })
    },

    inputFormObjectValues: ({ commit }, form) => {
      Object.entries(form).forEach(([key, value]) => {
        commit('setFormValue', { key, value })
      })
    },

    inputErrorObjectValues: ({ commit }, errors) => {
      Object.entries(errors).forEach(([key, value]) => {
        commit('setErrorValue', { key, value })
      })
    },    

    submitForm: async (_, { endpoint, form, sessionUuid }) => {      
      const response = await axios.post(`/${endpoint}/${sessionUuid}`, form)
      return response
    },    

    checkAvailableDates: async (_, { period, sessionUuid }) => {
      const { data: { availableDays } } = await axios.get(`installation/${sessionUuid}/days/${period}`)

      return availableDays
    },

    resetForm: async ({ commit }) => {
      commit('resetForm')
    },

    resetErrors: async ({ commit }) => {
      commit('resetErrors')
    },

    submitForm: async (_, { url, data }) => {
      try {
        const response = await axios.post(url, data)      
        return response
      } catch (e) {
        console.log(e);
        return e.response;
      }      
    },

    submitPortability: async (_, { url, data }) => {
      try {
        const response = await axios.post(url, data)      
        return response
      } catch (e) {
        console.log(e);
        return e.response;
      }      
    }
  },

  mutations: {
    setFormValue: (state, { key, value }) => {
      state.form[key] = value
    },

    setErrorValue: (state, { key, value }) => {
      state.errors[key] = value
    },

    resetForm: (state) => {
      Object.keys(state.form).forEach(key => {
        state.form[key] = ''
      })

      state.form.terms = false
    },

    resetErrors: (state) => {
      Object.keys(state.errors).forEach(key => {
        state.errors[key] = false
      })
    }
  }
}