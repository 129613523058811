import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default function useWeButton () {
  const store = useStore()
  const router = useRouter()

  const activeStep = computed(() => store.getters.getActiveStep)
  const stepRouteName = computed(() => store.getters.getStepper(activeStep.value).name)
  const nextStepRouteName = computed(() => store.getters.getStepper(activeStep.value + 1).name)

  function nextStep (phone) {

    if (!activeStep.value) store.dispatch('setActiveStep', 0)

    store.dispatch('updateStep', { stepState: activeStep.value, value: true })    

    if(!phone && activeStep.value == 2){
        store.dispatch('markCompleted', 3);
        router.push({ name: 'Payment', replace: true })
    }
    else {
        router.push({ name: nextStepRouteName.value, replace: true })
    }
     
  }

  async function checkAvailability (formData) {
    const sessionUuid = localStorage.getItem('session_uuid')
    
    const approved = await store.dispatch('checkAvailability', { formData, sessionUuid })
    
    return approved
  }

  const weButtonOptions = {
    'Confirm': {
      label: 'Contratar Plano',
      action: nextStep
    },
    'Availability': {
      label: 'Ver disponibilidade',
      action: checkAvailability
    },
    'ScheduleYourService': {
      label: 'Agendar contato',
      action: checkAvailability
    }
  }

  return { weButtonOptions, nextStep, checkAvailability }
}