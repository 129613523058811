import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import { mask } from 'vue-the-mask'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
    apiKey: '540e5546e45c1796a7455e7a410a53e8',
    plugins: [new BugsnagPluginVue()]
})
BugsnagPerformance.start({ apiKey: '540e5546e45c1796a7455e7a410a53e8' })

const bugsnagVue = Bugsnag.getPlugin('vue')

createApp(App)
    .directive('mask', mask)
    .use(store)
    .use(router)
    .use(bugsnagVue)
    .mount('#app')
