<template>

  <div class="skeleton desktop-layout relative flex h-screen flex-col" v-show="isLoading">

    <div class="w-full px-6 py-10 flex justify-between items-center"> 
      <div class="h-20 w-40 rounded-xl bg-gray-200 animate-pulse"></div>
      <div class="h-10 w-2/5 rounded-full bg-gray-200 animate-pulse"></div>
    </div>

    <div class="w-full px-6 mb-10">
      <div class="w-full py-3 px-4 flex flex-col rounded-xl border border-primary-brand-100 animate-pulse"> 
          <div class="w-1/4 h-4 rounded-full bg-gray-200 animate-pulse mb-3"></div>
          <div class="w-1/6 h-6 rounded-full bg-gray-200 animate-pulse mb-3"></div>
          <div class="w-2/5 h-6 rounded-full bg-gray-200 animate-pulse"></div>
        </div>
    </div>

    <div class="w-full bg-white px-6 flex flex-col justify-center items-center ">
        <div class="w-full flex justify-between mb-10"> 
          <div class="h-6 w-6 rounded-full bg-gray-200 animate-pulse"></div>
          <div class="h-6 w-6 rounded-full bg-gray-200 animate-pulse"></div>
          <div class="h-6 w-6 rounded-full bg-gray-200 animate-pulse"></div>
          <div class="h-6 w-6 rounded-full bg-gray-200 animate-pulse"></div>
        </div>
        <div class="w-full flex flex-col mb-10"> 
          <div class="h-8 w-1/2 rounded-full bg-gray-200 animate-pulse mb-4"></div>
          <div class="h-4 w-full rounded-full bg-gray-200 animate-pulse mb-2"></div>
          <div class="h-4 w-3/4 rounded-full bg-gray-200 animate-pulse"></div>
        </div>
        <div class="w-full flex flex-col mb-10">
          <div class="bg-gray-200 animate-pulse rounded-full w-full h-10 px-6 py-3 mb-3"></div>
          <div class="bg-gray-200 animate-pulse rounded-full w-full h-10 px-6 py-3 mb-3"></div>
          <div class="bg-gray-200 animate-pulse rounded-full w-full h-10 px-6 py-3 "></div>
        </div>
        <div class="w-full flex flex-col mb-10">
          <div class="h-4 w-2/5 rounded-full bg-gray-200 animate-pulse mb-2"></div>
          <div class="bg-gray-200 animate-pulse rounded-full w-full h-10 px-6 py-3 mb-3"></div>
          <div class="bg-gray-200 animate-pulse rounded-full w-full h-10 px-6 py-3"></div>
        </div>
        <div class="w-full flex">
          <div class="bg-gray-200 animate-pulse rounded-full w-2/3 h-10 px-6 py-3"></div>
        </div>        
    </div>

  </div>

  <div class="mobile-layout relative flex h-screen w-screen flex-col" v-show="!isLoading">
    <div class="w-screen" :class="mobileLayoutClasses, target">
      <we-header class="mb-10" :target="target"/>
      
      <div class="px-6" v-if="showHeading">
        <div v-if="isConfirmPage">
          <h1 class="bg-clip-text bg-gradient-to-r text-primary-brand-400 text-4xl font-extrabold">Ótima escolha!</h1>
          <p class="text-primary-brand-400 font-semibold text-lg">Você selecionou o plano:</p>
        </div>
        <div v-if="isConclusionPage">
          <h1 class="bg-clip-text bg-gradient-to-r text-primary-brand-400 text-4xl font-extrabold">Resumo do contrato</h1>
          <p class="text-primary-brand-200 font-semibold text-lg mb-8">Confirme os dados da sua contratação.</p>
          <p class="text-primary-brand-200 font-bold text-base">Plano selecionado:</p>
        </div>
      </div>

      <slot name="plan-information" />
      <slot name="content-mobile" />
      
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import { useSelectedPlanCard, useRouteName } from '@/composables';
import WeHeader from '@/components/WeHeader.vue'

const store = useStore()

const { useSmallSelectedPlan } = useSelectedPlanCard()
const { isConfirmPage, isConclusionPage } = useRouteName()

const mobileLayoutClasses = computed(() => {
  return !useSmallSelectedPlan.value && 'pb-36'
})

const target = computed(()=>{
  return store.getters.getTarget ? store.getters.getTarget : ''
})

const showHeading = computed(() => {
  return isConclusionPage.value || isConfirmPage.value
})

const isLoading = computed(() => {
  return store.getters.getLoading;
})
</script>

<style lang="scss" scoped>
  .we-screen{
    @apply bg-secondary-brand-300;

    .business {
      @apply bg-white;
    }
  }

  
</style>