import { ref } from 'vue'

//GLOBAL STATES
let isModalOpen = ref(false)

export default function useModal () {
  const openModal = () => {
    document.getElementsByTagName('body')[0].classList.add('overflow-hidden')
    isModalOpen.value = true
  }

  const hideModal = () => {
    document.getElementsByTagName('body')[0].classList.remove('overflow-hidden')
    isModalOpen.value = false
  }
  
  return { isModalOpen, openModal, hideModal }
}
