<template>
  <div class="rounded-full hover:ease-linear we-icon" v-bind="$attrs" :class="customClasses">
    <svg :width="iconWidth" :height="iconHeight">
      <use :href="`${icons}#${name}`" />
    </svg>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import icons from '@/assets/icons/icons.svg'

const props = defineProps({
  name: {
      type: String,
      required: true
    },

    size: {
      type: [Object, String],
      default: () => ({
        width: '20px',
        height: '20px'
      })
    },

    classes: {
      type: [String, Object],
      default: ''
    },

    options: {
      type: Object,
      default: () => ({})
    }
})

//icon
const iconWidth = computed(() => props.size.width || props.size)

const iconHeight = computed(() => props.size.height || props.size)

const customClasses = computed(() => {
  if (typeof props.classes === 'object') {
    const trueEntries = Object.entries(props.classes).filter(([_, value]) => !!value).map(([key, _]) => key)

    return trueEntries.join(' ') + ` bg-${props.options.bgColor}`
  }

  return props.classes + ` bg-${props.options.bgColor}`
})
</script>