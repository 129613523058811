<template>
  <div v-bind="$attrs" class="we-slider relative-position">
    <div ref="slider" class="we-slider__container flex w-full" :class="sliderContainerClasses" v-on="$listeners" @mousedown="mouseDown" @mousemove="mouseMove">
      <div ref="sliderContent" class="we-slider__content we-slider__list" :class="sliderListClasses">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'
export default {
  props: {
    expanded: {
      type: Boolean
    },
    animationOnScroll: {
      type: Boolean,
      default: true
    },
    sliderSlug: {
      type: String,
      default: ''
    },
    sliderListClasses: {
      type: [String, Object],
      default: ''
    },
    sliderContainerClasses: {
      type: [String, Object],
      default: ''
    }
  },
  data () {
    return {
      start: 1,
      difference: 0,
      drag: false,
      hideBar: false,
      element: null,
      hiddenArrow: false,
      position: 0,
      lastCardChild: null
    }
  },
  computed: {
    expandedContainerClass () {
      return this.expanded ? 'we-slider__container--expanded' : ''
    }
  },
  mounted () {
    this.getPosition()
    this.lastCardChild = this.$refs.sliderContent.lastChild
    this.element = this.$refs.slider
    window.__forceSmoothScrollPolyfill__ = true
    smoothscroll.polyfill()
    window.addEventListener('mouseup', this.mouseUp)
  },
  destroyed () {
    window.removeEventListener('mouseup', this.mouseUp)
  },
  methods: {
    mouseDown (event) {
      if (!event) {
        event = window.event
      }
      event.preventDefault()
      this.start = event.clientX + this.element.scrollLeft
      this.difference = 0
      this.drag = true
    },
    mouseMove (event) {
      if (!this.drag) {
        return null
      }
      if (!event) {
        event = window.event
      }
      event.preventDefault()
      this.difference = this.start - (event.clientX + this.element.scrollLeft)
      this.element.scrollLeft += this.difference
      this.hiddenScrollArrow()
    },
    mouseUp (event) {
      let tick = 1
      if (!event) {
        event = window.event
      }
      event.preventDefault()
      this.drag = false
      const animate = () => {
        const step = Math.sin(tick)
        if (step <= 0) {
          this.difference = 0
          window.cancelAnimationFrame(animate)
        } else {
          this.element.scrollLeft += this.difference * step
          tick -= 0.02
          window.requestAnimationFrame(animate)
        }
      }
      this.animationOnScroll && animate()
    },
    getPosition () {
      this.position = this.$refs.sliderContent?.lastChild?.offsetLeft
    },
    next () {
      this.element.scroll({
        left: this.element.scrollLeft + this.lastCardChild.offsetWidth,
        behavior: 'smooth'
      })
      this.hiddenScrollArrow()
    },
    hiddenScrollArrow () {
      const hidden = window.innerWidth + this.element.scrollLeft
      this.hiddenArrow = hidden > this.position
    }
  }
}
</script>

<style lang='scss'>
.we-slider {
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  & > * {
    scroll-behavior: initial;
  }

  &::-webkit-scrollbar {
    display: none !important;
  }

  &__container {
    cursor: grab;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow-x: auto;
    position: relative;
    scrollbar-width: none;
    white-space: nowrap;
    &::-webkit-scrollbar {
      width: 0 !important;
      display: none !important;
    }
  }

  &__content {
    display: flex;
    height: auto;
  }

  &__content::-webkit-scrollbar {
    display: none;
  }

  &__list {
    margin: 0 20px 0;
  }

  &__list::-webkit-scrollbar {
    display: none;
  }
}
</style>