<template>
  <div v-bind="$attrs" class="we-blurred-box p-6 border-2 border-primary-brand-100">
    <slot/>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.we-blurred-box {
  background-color: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(6px);
}
</style>