/**
 * LG -> 1920
 * MD -> 1366
 * SM -> 768
 * XS -> 320
 */

import { ref, reactive } from 'vue'

//GLOBAL STATES
let listeners = reactive({ resize: false })
let isSmall = ref(false)

export default function useScreen () {
  function setResizeListener () {
    isSmall.value = window.innerWidth <= 768

    if (!listeners.resize) {
      window.addEventListener('resize', e => {
        isSmall.value = e.target.innerWidth <= 768
      })

      listeners.resize = true
    }
  }
  
  return { isSmall, setResizeListener }
}
