<template>
  
  <div v-show="hasSelectedPlan" class="we-selected-plan-card">
    <div class="bg-white flex justify-between border-2 border-primary-brand-100 rounded-xl shadow-sm" :class="classes">
      
      <div v-if="useSmallSelectedPlan" class="flex justify-between w-full">
        <div>
          <p class="text-primary-brand-300 font-semibold text-xs">Plano selecionado</p>
          <p class="text-primary-brand-400 font-semibold text-lg">{{ selectedPlan.product?.title }}</p>
          <div class="flex items-end mt-1">

            <div class="flex flex-col items-start">
                <div v-if="hasDiscount" class="text-primary-brand-200 line-through text-xs flex items-center mr-2">                  
                  R${{ selectedPlan.product?.full_price ?? '' }}/mês
              </div>
              <div class="text-contrast-brand-300 font-semibold text-sm flex items-center mr-3">
                  <we-icon name="payments_filled" size="16px" class="mr-1 fill-contrast-brand-300"/>
                  R${{ selectedPlan.price?.full_price ?? '' }}/mês
              </div>
            </div>
            
            <div class="text-contrast-brand-300 font-semibold text-sm flex items-center">   
                <we-icon name="wifi" size="16px" class="mr-1 fill-contrast-brand-300"/> 
                <div class="flex flex-col">
                  {{ hasCombo ? selectedPlan.product?.plans[0].describe : selectedPlan.product?.describe }}
                </div>
            </div>

          </div>
        </div>
        <div>
          <we-button v-if="showChangePlanButton" changePlan label="Trocar" small border-color="primary-brand-100" :loading="isLoading" :icon-options="weButtonIconOptions" @click="openModal"/>
        </div>
      </div>

      <div v-else class="flex justify-between flex-col w-full">
        <div class="mb-8">
          <div class="flex justify-between flex-wrap">
            <div>
              <p class="text-primary-brand-400 font-extrabold text-2xl">{{ selectedPlan.product?.title }}</p>
              <p v-if="hasDiscount" class="text-primary-brand-200 font-semibold text-md line-through text-md opacity-60 my-1"> 
                R${{ selectedPlan.product?.price ?? '' }},{{ selectedPlan.product?.price_decimal ?? '' }}/mês</p>
              <p  class="text-primary-brand-200 font-semibold text-2xl">
                  R$ {{ selectedPlan.price?.full_price ?? '' }}/mês
              </p>          
              <p v-if="selectedPlan.product?.mostSold" class="text-primary-brand-300 italic font-medium flex text-sm mt-3">
                <we-icon name="workspace_premium" class="fill-contrast-brand-300 mr-2"/>
                O plano mais vendido do ano!
              </p>
            </div>
            <div>
              <we-button v-if="showChangePlanButton" changePlan label="Trocar" small border-color="primary-brand-100" :loading="isLoading" :icon-options="weButtonIconOptions" @click="openModal"/>
            </div>
          </div>
        </div>
        <div v-if="hasCombo">
            <div v-for="(product, index) in selectedPlan.product?.plans" :key="index">
              <div class="flex items-center font-medium text-base text-primary-brand-300 mb-4">
                  <div class="w-11 h-11 rounded-full border-2 border-secondary-brand-200 bg-white mr-2">
                    <img :src="product.icon" class="w-full h-full object-contain p-2"/>
                  </div>
                  
                  {{ product.describe }}
              </div>
            </div>
        </div>
        <div v-else>
            <div>
              <p class="w-full flex items-center font-medium text-base text-primary-brand-300 mb-4">
                  <img :src="selectedPlan.product?.icon" class="fill-primary-brand-300 bg-secondary-brand-300 p-3 mr-3 aspect-square w-10 object-contain rounded-full"/> 
                  {{ selectedPlan.product?.describe }}
              </p>
            </div>
        </div>
      </div>

    </div>

    <we-informational-card v-if="showInformationalCard" class="mt-4"/>
  </div>

  <div class="selectPlanModal">
    <we-modal v-model="isPlanModalOpen" modal-size="max-w-[90vw] px-10">
      <template #header>
        <div>        
          <div class="font-bold text-2xl text-primary-brand-400 text-center">Selecione o plano</div>   
          <div class="font-semibold text-primary-brand-300 text-center">Com franquia liberada, para navegar e curtir sem preocupação.</div>
          <div class="border border-primary-brand-100 rounded-3xl overflow-hidden flex mx-auto mt-8 mb-10 group is-published" :class="[typeSelectionClasses]">
            <div class="py-2 text-center w-[120px] font-medium cursor-pointer" :class="{ 'bg-secondary-brand-200' : planType === 'plans' }" @click="togglePlanType('plans')">Internet</div>
            <div class="py-2 text-center w-[120px] font-medium cursor-pointer" :class="{ 'bg-secondary-brand-200' : planType === 'combos' }" @click="togglePlanType('combos')">Combos</div>
          </div>
        </div>

      </template>
      <template #content>

        <div class="mt-4">

          <div v-if="planType == 'plans'">          
            <we-slider :slider-list-classes="sliderListPositioning" :slider-container-classes="sliderPositioning">
              <div v-for="(plan, index) in availablePlans" :key="index" class="py-10 relative flex justify-center items-start gap-x-5">
                <we-plan-card :plan="plan" @selected-plan="changePlan"/>
              </div>
            </we-slider>
          </div>

          <div v-if="planType == 'combos'">
            <we-slider :slider-list-classes="sliderListPositioning" :slider-container-classes="sliderPositioning">
              <div v-for="(plan, index) in availableCombos" :key="index" class="py-10 relative flex justify-center items-start gap-x-5">
                <we-plan-card :plan="plan" @selected-plan="changePlan"/>
              </div>
            </we-slider>
          </div>

        </div>

      </template>
    </we-modal>
  </div>
  
</template>

<script setup>
import { ref, computed} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useSelectedPlanCard, useScreen } from '@/composables';
import { formatCurrency } from '@/assets/helpers'
import WeButton from './WeButton'
import WeIcon from './WeIcon'
import WeInformationalCard from './WeInformationalCard'
import WeModal from './WeModal'
import WeSlider from './WeSlider'
import WePlanCard from './WePlanCard'
import WeSelect from './WeSelect.vue';

const store = useStore()

const isPlanModalOpen = ref(false)

const planType = ref('plans');

const isLoading = ref(false);

const togglePlanType = (type) => {
  planType.value = type
}

const { useSmallSelectedPlan, showInformationalCard } = useSelectedPlanCard()

const { isSmall } = useScreen()

const route = useRoute()

//selected plan card
const classes = computed(() => useSmallSelectedPlan.value ? 'py-3 px-4' : 'p-6')

const selectedPlan = computed(() => store.getters.getSelectedPlan)

const hasSelectedPlan = computed(() => Object.keys(selectedPlan.value).length)

const discount = computed(() => store.getters.getDiscount);

const hasDiscount = computed(() => Object.keys(discount.value).length > 0)

const openModal = () => isPlanModalOpen.value = true

const closeModal = () => isPlanModalOpen.value = false

const sessionUuid = computed( () => store.state.selectedPlan.uuid )
const selectedCity = computed(()=> store.getters.getSelectedCity.slug) 

const changePlan = (plan) => {

  closeModal();

  isLoading.value = true

  store.dispatch('updatePlanOrCity', { 
    sessionUuid: sessionUuid.value,
    city: selectedCity.value,
    product: plan.id,
    type: plan.type,
  }).finally(()=>{
    isLoading.value = false
  })
    
}

const showChangePlanButton = computed(() => route.name == 'Availability')

const hasCombo = computed(() => selectedPlan.value.product?.plans)

const typeSelectionClasses = computed(() => hasCombo.value ? 'w-[240px]' : 'w-[120px]')

const plansList = computed(() => store.getters.getPlansList)

const sliderPositioning = computed(() => {
  if (isSmall.value) return availablePlans.value <= 2 ? 'justify-center' : ''

  return availablePlans.value.length <= 4 ? 'justify-center' : ''
})

const sliderListPositioning = computed(() => {
  if (isSmall.value) return plansList.value.length == 1 ? 'justify-center w-full' : 'gap-x-5'

  return plansList.value <= 4 ? 'justify-center w-full' : 'gap-x-5'
})

//WeButton props
const weButtonIconOptions = computed(() => {
  return {
    classes: 'p-0',
    size: '14px'
  }
})

const target = computed(()=>{
  return store.getters.getTarget ? store.getters.getTarget : 'plan'
})

const availablePlans = computed(()=>{
  return store.getters.getPlansList?.plans[target.value];
})

const availableCombos = computed(()=>{
  return store.getters.getPlansList?.combos[target.value];
})


</script>


<style lang="scss">
  .selectPlanModal {
    @media (max-height: 569px) {
      &__subtitle {
        display: none;
      }
    }  
  }
  .we-selected-plan-card > .business{
    @apply shadow-none
  }
</style>