import axios from 'axios'

axios.create({
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': "application/json, text/plain"
  }
});

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

export default axios