import axios from "axios"

export default {
  state: {
    isLoading: true,
  },

  getters: {
    getLoading: state => state.isLoading,
  },

  actions: {
    setLoading ({ commit }, status) {
        setTimeout(() => {
            commit('setLoading', status)
        }, 800);        
    },
  },

  mutations: {
    setLoading: (state, { value }) => {
      state.isLoading = value
    },
  }
}